import { graphql, useStaticQuery } from 'gatsby'

export interface ContactInformation {
    address: {
        doorNumber: number
        streetName: string
        city: string
        postalCode: string
        googleMapUrl: string
    }
    phoneNumber: string
    phoneNumberUri: string
    contactEmail: string
    amfNumber: number
}

export const useContactInformation = (): ContactInformation => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        phoneNumber
                        phoneNumberUri
                        contactEmail
                        amfNumber
                        address {
                            doorNumber
                            streetName
                            city
                            postalCode
                            googleMapUrl
                        }
                    }
                }
            }
        `,
    )

    return {
        address: site.siteMetadata.address,
        contactEmail: site.siteMetadata.contactEmail,
        phoneNumber: site.siteMetadata.phoneNumber,
        phoneNumberUri: site.siteMetadata.phoneNumberUri,
        amfNumber: site.siteMetadata.amfNumber,
    }
}
