export enum Colors {
    white = '#fff',
    lightBlue = '#cdd6e0',
    lightausBlue = '#235a91',
    darkBlue = '#1b3254',
    yellow = '#fcc805',
    orange = '#fc742d',
    skyBlue = '#2499ce',
    turquoise = '#4ef5bf',
    purple = '#b795d1',
}

const colorMappings = {
    [Colors.skyBlue]: Colors.yellow,
    [Colors.orange]: Colors.skyBlue,
    [Colors.purple]: Colors.orange,
    [Colors.yellow]: Colors.turquoise,
    [Colors.turquoise]: Colors.purple,
    [Colors.lightausBlue]: Colors.yellow,
}

export const getColorTheme = (primaryColor: Colors) => ({
    primary: primaryColor,
    secondary: colorMappings[primaryColor],
})
