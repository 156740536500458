import emailjs from 'emailjs-com'
import { TemplateParameters } from './templateParameters'
import { TemplateType } from './templateParameters/contactFormTemplateParameter'

export interface SendEmailResult {
    error: boolean
    message: string
    errorDetail?: object
}

export const sendEmail = async (params: TemplateParameters): Promise<SendEmailResult> => {
    let templateId = ''

    if (params.template === TemplateType.Contact) {
      templateId = process.env.GATSBY_EMAILJS_TEMPLATE_ID
    } else if (params.template === TemplateType.Mortgage) {
      templateId = process.env.GATSBY_EMAILJS_MORTGAGE_TEMPLATE_ID
    }


    const fromEmail = process.env.GATSBY_EMAILJS_FROM_EMAIL

    const templateParams = {
        ...params,
        ...(fromEmail && { from_email: fromEmail }),
    }

    try {
        await emailjs.send(
            process.env.GATSBY_EMAILJS_SERVICE_ID,
            templateId,
            templateParams,
            process.env.GATSBY_EMAILJS_USER_ID,
        )
    } catch (error) {
        return {
            error: true,
            message: 'Votre demande a échoué, veuillez réessayer.',
            errorDetail: error,
        }
    }

    return {
        error: false,
        message:
            'Votre demande a été acheminée avec succès, un représentant de Lightaus entrera en contact avec vous sous peu.',
    }
}

