import { object, string } from 'yup'

export const contactFormValidationSchema = object().shape({
    name: string().required('Requis'),
    email: string().email('Courriel invalide'),
    tel: string().matches(/^[\+]?[0-9]?[-\s\.]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, {
        message: 'Téléphone invalide',
    }),
    emailOrTel: string().test({
        name: 'emailOrTel',
        test: function(value) {
            return this.parent.email || this.parent.tel
        },
        message: "Vous devez entrer une façon d'être communiqué",
    }),
    message: string().required('Requis'),
    recaptcha: string().required('Vous devez cocher cette case.'),
})

export const mortgageValidationSchema = object().shape({
    name: string().required('Requis'),
    email: string()
        .email('Courriel invalide')
        .required('Requis'),
    tel: string()
        .matches(/^[\+]?[0-9]?[-\s\.]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, {
            message: 'Téléphone invalide',
        })
        .required('Requis'),
    province: string().required('Requis'),
    language: string().required('Requis'),
    message: string(),
    recaptcha: string().required('Vous devez cocher cette case.'),
})
