import React, { FC } from 'react'
import ColorBox from '../../components/color-box/color-box'
import { graphql, useStaticQuery } from 'gatsby'
import { Colors } from '../../colors'
import Img from 'gatsby-image'
import { Routes } from '../../routes'

export const LiquiditesBox: FC = () => {
    const data = useStaticQuery(graphql`
        query {
            gestionBackground: file(absolutePath: { regex: "/services/liquidites.jpg/" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <ColorBox
            image={<Img fluid={data.gestionBackground.childImageSharp.fluid} />}
            color={Colors.yellow}
            label="Gestion de liquidités"
            navigatesTo={Routes.gestionDeLiquidites}
        />
    )
}
