export enum Routes {
    home = '/fr/',
    contact = '/fr/contact/',
    services = '/fr/services/',
    equipe = '/fr/equipe/',
    assuranceDePersonnes = '/fr/services/assurance-de-personne/',
    autresServices = '/fr/services/autres-services/',
    gestionDeLiquidites = '/fr/services/gestion-de-liquidites/',
    placements = '/fr/services/placements/',
    financementHypothecaire = '/fr/services/financement-hypothecaire/',
}
