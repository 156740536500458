import React, { FC } from 'react'
import ColorBox from '../../components/color-box/color-box'
import { graphql, useStaticQuery } from 'gatsby'
import { Colors } from '../../colors'
import Img from 'gatsby-image'
import { Routes } from '../../routes'

export const PlacementBox: FC = () => {
    const data = useStaticQuery(graphql`
        query {
            placementsBackground: file(absolutePath: { regex: "/services/placements.jpg/" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <ColorBox
            image={<Img fluid={data.placementsBackground.childImageSharp.fluid} />}
            color={Colors.turquoise}
            label="Placement et gestion financière"
            navigatesTo={Routes.placements}
        />
    )
}
