export interface ContactFormTemplateParameter {
    from_name: string
    reply_to: string
    message_html: string
    phone_number: string
    template: TemplateType
}

export interface MortgageTemplateParameter extends ContactFormTemplateParameter {
  province: string
  language: string
}

export enum TemplateType {
  Contact = 'contact',
  Mortgage = 'mortgage',
}
