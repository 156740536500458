import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import './layout.scss'
import Helmet from 'react-helmet'
import Footer from '../footer/footer'
import { Colors } from '../../colors'
import SEO from '../blocs/seo'
import { injectIntl, IntlShape } from 'gatsby-plugin-intl'
import { Header } from '../header/header'
import { MobileNavigation } from '../mobile-navigation/mobile-navigation'
import { useLayoutInformation } from '../../hooks/useLayoutInformation'

export interface LayoutQuery {
    site: {
        siteMetadata: {
            title: string
        }
    }
}

export const layoutQuery = graphql`
    query SiteTitleQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`

export interface Props {
    backgroundColor?: string
    descriptionIntlId?: string
    intl?: IntlShape
    titleIntlId: string
    className?: string
}

const Layout: React.FC<Props> = ({ intl, ...props }) => {
    const { formatMessage } = intl
    const { site } = useStaticQuery<LayoutQuery>(layoutQuery)
    const {
        backgroundColor = Colors.white,
        children,
        descriptionIntlId = 'description',
        titleIntlId = 'title',
        className = '',
    } = props
    const { isMobile } = useLayoutInformation()

    return (
        <>
            <Helmet>
                <link
                    href="https://fonts.googleapis.com/css?family=Lato:400,700|Montserrat:700,900&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
            <Header />
            <main style={{ backgroundColor }} className={className}>
                <SEO
                    title={formatMessage({ id: titleIntlId })}
                    description={formatMessage({ id: descriptionIntlId })}
                />
                {children}
            </main>
            <Footer siteTitle={site.siteMetadata.title} />
            {isMobile && <MobileNavigation />}
        </>
    )
}

export default injectIntl(Layout)
