import React, { FC, useMemo } from 'react'
import * as styles from './services.module.scss'
import { AssurancesBox } from '../services/assurances'
import { PlacementBox } from '../services/placements'
import { LiquiditesBox } from '../services/liquidites'
import { AutresBox } from '../services/autres'
import { AvailableServices } from '../../available-services'
import { FinancementHypothecaireBox } from '../services/financement-hypothecaire'

export interface Props {
    overlapPreviousElement?: boolean
    serviceToHide?: AvailableServices
}

const Services: FC<Props> = ({ overlapPreviousElement = false, serviceToHide }) => {
    const innerStyle = useMemo(() => (overlapPreviousElement ? { marginTop: '-100px' } : null), [
        overlapPreviousElement,
    ])

    return (
        <div className={styles.services}>
            <div className={styles.services_container} style={innerStyle}>
                {serviceToHide !== AvailableServices.financementHypothecaire && <FinancementHypothecaireBox />}
                {serviceToHide !== AvailableServices.assurance && <AssurancesBox />}
                {serviceToHide !== AvailableServices.placements && <PlacementBox />}
                {serviceToHide !== AvailableServices.liquidites && <LiquiditesBox />}
                {serviceToHide !== AvailableServices.autres && <AutresBox />}
            </div>
        </div>
    )
}

export default Services
