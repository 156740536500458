import * as React from 'react'
import { ErrorMessage, Field } from 'formik'
import * as styles from './form-input.module.scss'
import classnames from 'classnames/bind'

export interface Props {
    component?: string
    label?: string
    name: string
    type?: string
    size?: string
}

const bindedClasses = classnames.bind(styles)

export const FormInput: React.FC<Props> = ({
    name,
    label = name,
    component = 'input',
    type = 'text',
    size = 'full',
}) => {
    const halfSize = size === 'half'

    const classes = bindedClasses({
        form_control: true,
        half: halfSize,
    })

    return (
        <>
            <div className={classes}>
                <label htmlFor={name}>{label}</label>
                <Field name={name} id={name} type={type} placeholder={label} component={component} />
                <ErrorMessage name={name} className={styles.error} component={'p'} />
            </div>
        </>
    )
}
