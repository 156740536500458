import React, { useMemo } from 'react'
import * as styles from './hero.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import Button from '../button/button'
import classnames from 'classnames/bind'

export interface Props {
    backgroundImageUri: string
    backgroundPosition?: string
    buttonColor?: string
    fullWidthContent?: boolean
    message: string
    buttonText?: string
}

const boundClasses = classnames.bind(styles)

export const Hero: React.FC<Props> = ({
    backgroundImageUri,
    backgroundPosition = '0% 0%',
    buttonColor,
    fullWidthContent = false,
    message,
    buttonText = 'Prendre rendez-vous'
}) => {
    const backgroundImageStyle = useMemo(
        () => ({
            backgroundImage: `url(${backgroundImageUri})`,
            backgroundPosition,
        }),
        [backgroundImageUri, backgroundPosition],
    )
    const innerStyles = useMemo(
        (): string =>
            boundClasses({
                inner: true,
                [styles.fullWidth]: fullWidthContent,
            }),
        [fullWidthContent],
    )

    return (
        <div className={styles.container} style={backgroundImageStyle}>
            <div className={styles.wrapper}>
                <div className={innerStyles}>
                    <h1>
                        <FormattedMessage id={message} />
                    </h1>
                    <Button
                        text={buttonText}
                        color={buttonColor}
                        onClick={() => {
                            window.open('https://calendly.com/lightaus/suivi', '_blank')
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
