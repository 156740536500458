import React, { FC } from 'react'
import { GraphicOnlyLogo } from '../lightaus-logo/lightaus-logo'
import * as styles from './service-detail.module.scss'
import * as icons from '../icons'
interface Props {
    color: string
    title: string
    details?: string
    icon?: string
}

export const ServiceDetail: FC<Props> = ({ color, title, details, icon = null }) => {
    const Icon = icon ? icons[icon] : null
    return (
        <div className={styles.serviceDetail}>
            <div>{(Icon && <Icon color={color} />) || <GraphicOnlyLogo color={color} />}</div>
            <div className={!details && styles.centeredContent}>
                <h3>{title}</h3>
                {details && <p>{details}</p>}
            </div>
        </div>
    )
}
