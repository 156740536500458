import React, { FC } from 'react'
import Layout from './layout'
import { Hero } from '../hero/hero'
import { Colors } from '../../colors'
import { useLayoutInformation } from '../../hooks/useLayoutInformation'
import { PageRow } from '../page-row/page-row'
import * as styles from './service.module.scss'
import Services from '../blocs/services'
import { AvailableServices } from '../../available-services'

interface Props {
    primaryColor: Colors
    title: string
    desktopBanner: string
    mobileBanner: string
    bannerText: string
    serviceBrief: string
    serviceToHide: AvailableServices
}

export const ServicePageLayout: FC<Props> = ({
    title,
    children,
    primaryColor,
    bannerText,
    desktopBanner,
    mobileBanner,
    serviceBrief,
    serviceToHide,
}) => {
    const { isMobile, secondaryColor } = useLayoutInformation(primaryColor)

    return (
        <Layout titleIntlId={title}>
            <Hero
                backgroundImageUri={isMobile ? mobileBanner : desktopBanner}
                message={bannerText}
                buttonColor={secondaryColor}
            />
            <PageRow className={styles.description}>
                <h2>{title}</h2>
                <p>{serviceBrief}</p>
            </PageRow>
            <PageRow fullWidth className={styles.serviceDetails}>
                {children}
            </PageRow>
            <PageRow className={styles.services}>
                <h2>Découvrez nos autres services</h2>
                <Services serviceToHide={serviceToHide} />
            </PageRow>
        </Layout>
    )
}

export default ServicePageLayout
