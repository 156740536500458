import React, { useMemo } from 'react'
import * as styles from './button.module.scss'
import classnames from 'classnames/bind'
import { Link } from 'gatsby'
import { Colors } from '../../colors'

interface Props {
    color?: string
    linkTo?: string
    displayOnMobileOnly?: boolean
    text: string
    onClick?: () => void
}

const boundClasses = classnames.bind(styles)

const Button: React.FC<Props> = ({
    color = Colors.lightausBlue,
    linkTo,
    displayOnMobileOnly = false,
    text,
    onClick,
}) => {
    const classes = useMemo(
        (): string =>
            boundClasses({
                button: true,
                default: color === Colors.lightausBlue,
                orange: color === Colors.orange,
                yellow: color === Colors.yellow,
                turquoise: color === Colors.turquoise,
                sky: color === Colors.skyBlue,
                purple: color === Colors.purple,
                responsive_only: displayOnMobileOnly,
            }),
        [displayOnMobileOnly, color],
    )

    if (!linkTo) {
        return (
            <a className={classes} onClick={onClick}>
                {text}
            </a>
        )
    }

    return (
        <Link to={linkTo} className={classes} onClick={onClick}>
            {text}
        </Link>
    )
}

export default Button
