import React, { CSSProperties, FC, useMemo } from 'react'
import * as styles from './page-row.module.scss'

interface Props {
    fullWidth?: boolean
    backgroundImage?: string
    backgroundColor?: string
    className?: string
}

export const PageRow: FC<Props> = ({
    fullWidth = false,
    children,
    backgroundImage,
    backgroundColor,
    className = null,
}) => {
    const style = useMemo((): CSSProperties => {
        return !backgroundImage && !backgroundColor
            ? null
            : {
                  backgroundColor,
                  backgroundImage: `url(${backgroundImage})`,
              }
    }, [backgroundImage, backgroundColor])

    return (
        <div className={`${fullWidth ? null : styles.row}`} style={style}>
            <div className={`${className ? className : ''}`}>{children}</div>
        </div>
    )
}
