import React, { FC } from 'react'
import ColorBox from '../../components/color-box/color-box'
import { graphql, useStaticQuery } from 'gatsby'
import { Colors } from '../../colors'
import Img from 'gatsby-image'
import { Routes } from '../../routes'

export const AssurancesBox: FC = () => {
    const data = useStaticQuery(graphql`
        query {
            assuranceBackground: file(absolutePath: { regex: "/services/assurances.jpg/" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <ColorBox
            image={<Img fluid={data.assuranceBackground.childImageSharp.fluid} />}
            color={Colors.orange}
            label="Assurance de personnes"
            navigatesTo={Routes.assuranceDePersonnes}
        />
    )
}
