import React, { FC } from 'react'
import { Link } from 'gatsby'
import { GatsbyLinkProps } from 'gatsby-link'
import { Routes } from '../../routes'

type Props = Pick<GatsbyLinkProps<unknown>, 'to' | 'className' | 'activeClassName'>

export const MenuLink: FC<Props> = ({ children, ...props }) => (
    <Link
        partiallyActive={true}
        getProps={({ href, isCurrent, isPartiallyCurrent }) => {
            if (href === Routes.home && !isCurrent) {
                return {}
            }

            return isCurrent || isPartiallyCurrent ? { className: props.activeClassName } : {}
        }}
        {...props}
    >
        {children}
    </Link>
)
