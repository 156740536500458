import React, { FC } from 'react'

interface Props {
  color: string
}

export const HouseIcon: FC<Props> = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 733.3 652">
        <path
            fill={color}
            d="m131 650-2-2c-2 0-4-2-6-5-2-4-2-6-2-153 0-131 0-148-2-149-2 0-5 2-11 7-11 10-14 11-20 11-8 0-14-8-12-17 2-4 285-246 290-247h7c3 1 269 227 283 240 6 6 7 12 4 17-2 5-6 7-12 7-5 0-7-1-19-11-5-5-9-7-10-7-2 1-2 18-2 149 0 147 0 149-3 153-1 3-4 5-5 5-2 1-3 1-2 2l-238 1c-139 0-239 0-238-1zm143-28c3-1 3-4 3-95 0-101 0-100 6-104 4-2 168-2 171 0 7 4 7 3 7 104 0 92 0 94 2 95 4 2 124 1 125 0 2-2 3-298 1-305 0-4-15-17-109-97l-111-92c-4 0-220 184-221 188v303l3 3c5 2 121 1 123 0zm157 0c3-1 3-4 3-86 0-64 0-85-2-86-2-2-125-2-127 0-1 1-2 22-2 86 0 79 1 85 3 86 3 2 122 2 125 0zM15 328c-6-3-10-11-8-18A46424 46424 0 0 1 360 8c2-2 6-3 9-3 6 0 3-3 66 51l51 42c2-1 2-6 3-34 0-24 1-34 2-36 4-7 8-7 49-7 34 0 39 0 43 2 3 1 6 4 7 6l1 81 1 79 69 60c41 34 68 59 69 61 4 10-6 21-16 17A514859 514859 0 0 0 368 37L228 156 58 300c-34 28-36 30-43 28Zm549-168V51c-1-2-4-2-23-2-21 0-23 0-24 2-2 3-2 67-1 72 1 3 42 39 45 39l3-2zM367 2h3c1 1 1 1-1 1s-3 0-2-1z"
        />
    </svg>
)
