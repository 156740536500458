import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

export interface Props {
    description?: string
    lang?: string
    meta?: Array<{
        name?: string
        content?: string
        property?: string
    }>
    title: string
}

export interface SeoQuery {
    site: {
        siteMetadata: {
            title: string
            description: string
            author: string
        }
    }
}

export const seoQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`

export const SEO: React.FC<Props> = ({ description = '', lang = 'fr', meta = [], title }) => {
    const { site } = useStaticQuery<SeoQuery>(seoQuery)

    const metaDescription = description || site.siteMetadata.description

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta as [])}
        />
    )
}

export default SEO
