import { Colors, getColorTheme } from '../colors'
import { useMediaQuery } from 'react-responsive'
import { useMemo } from 'react'

export const useLayoutInformation = (primaryColor: Colors = Colors.lightausBlue) => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    return useMemo(
        () => ({
            isMobile,
            primaryColor: Colors.lightausBlue,
            secondaryColor: getColorTheme(primaryColor).secondary,
        }),
        [primaryColor, isMobile],
    )
}
