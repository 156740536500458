import React, { FC } from 'react'
import { useLayoutInformation } from '../../hooks/useLayoutInformation'
import { useContactInformation } from '../../hooks/useContactInformation'
import * as styles from './header.module.scss'
import { Routes } from '../../routes'
import { FullLogo } from '../lightaus-logo/lightaus-logo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt, faPhone, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { Menu } from '../menu/menu'
import { MenuLink } from '../link/menuLink'
import {faFacebook, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import Button from '../button/button'

const ContactInformation: FC<{ icon: IconDefinition; uri?: string; text: string }> = props => {
    const { icon, text, uri } = props

    return (
        <p>
            <FontAwesomeIcon icon={icon} size={'1x'} />
            <a href={uri}>{text}</a>
        </p>
    )
}

export const Header: FC = () => {
    const { isMobile } = useLayoutInformation()
    const { address, amfNumber, contactEmail, phoneNumber, phoneNumberUri } = useContactInformation()

    return (
        <header className={styles.header}>
            <div className={styles.navigation}>
                <div className={styles.logo}>
                    <MenuLink to={Routes.home}>
                        <FullLogo />
                    </MenuLink>
                </div>
                {!isMobile && (
                    <div className={styles.menu}>
                        <Menu />
                    </div>
                )}
            </div>
            <div className={styles.access}>
                <Button text={"Accès Client"} onClick={() => window.location.href = "https://quadrus.univeriscloud.com/account/login"}  />
            </div>
            <div className={styles.information}>
                {!isMobile && (
                    <ContactInformation
                        icon={faMapMarkerAlt}
                        uri={address.googleMapUrl}
                        text={`${address.doorNumber} ${address.streetName}, ${address.city}`}
                    />
                )}
                <ContactInformation icon={faPhone} uri={`tel:${phoneNumberUri}`} text={phoneNumber} />
                <ContactInformation icon={faEnvelope} uri={`mailto:${contactEmail}`} text={contactEmail} />
                <ContactInformation icon={faFacebook} text={'Facebook'} uri={'https://www.facebook.com/lightausgroupefinancier'} />
                <ContactInformation icon={faLinkedin} text={'Linkedin'} uri={'https://www.linkedin.com/company/lightaus-groupe-financier/'} />
                <p>
                    <strong>Numéro AMF:</strong> {amfNumber}
                </p>
            </div>
        </header>
    )
}
