import React, { ReactNode, useState } from 'react'
import * as styles from './color-box.module.scss'
import { Link } from 'gatsby'

export interface Props {
    image: React.ReactNode
    color?: string
    label?: string
    navigatesTo?: string
}

const withLink = (elements: ReactNode, link?: string): ReactNode => {
    return link ? <Link to={link}>{elements}</Link> : elements
}

const ColorBox: React.FC<Props> = ({ image, color, label, navigatesTo }) => {
    return (
        <div className={styles.color_box}>
            <div className={styles.imageContainer}>{withLink(image, navigatesTo)}</div>
            {color && label && (
                <div style={{ backgroundColor: color }} className={styles.labelContainer}>
                    {withLink(<p className={styles.label}>{label}</p>, navigatesTo)}
                </div>
            )}
        </div>
    )
}

export default ColorBox
