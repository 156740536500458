import { Routes } from './routes'

export interface MenuItem {
    label: string
    page: string
    children?: MenuItemCollection
}

export type MenuItemCollection = MenuItem[]

export const menu: MenuItemCollection = [
    { label: 'Accueil', page: Routes.home },
    {
        label: 'Services',
        page: Routes.services,
        children: [
            { label: 'Financement hypothécaire', page: Routes.financementHypothecaire},
            { label: 'Assurance de personnes', page: Routes.assuranceDePersonnes },
            { label: 'Placement & gestion financière', page: Routes.placements },
            { label: 'Gestion de liquidités', page: Routes.gestionDeLiquidites },
            { label: 'Autres services', page: Routes.autresServices },
        ],
    },
    { label: 'Équipe', page: Routes.equipe },
    { label: 'Contact', page: Routes.contact },
]
