import * as React from 'react'
import * as styles from './submit-button.module.scss'

export interface Props {
    disabled?: boolean
    label?: string
}

export const SubmitButton: React.FC<Props> = ({ label = 'Envoyer', disabled = false }) => (
    <button type="submit" className={styles.button} disabled={disabled}>
        {label}
    </button>
)
