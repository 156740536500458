import React, { FC, useState } from 'react'
import * as styles from './mobile-navigation.module.scss'
import { Routes } from '../../routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faEnvelope, faHome, faTimes, faUserFriends, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { Menu } from '../menu/menu'
import { FullLogo } from '../lightaus-logo/lightaus-logo'
import { Colors } from '../../colors'
import { MenuLink } from '../link/menuLink'

interface NavItemProps {
    icon: IconDefinition
    label: string
    url: string
}

const NavItem: FC<NavItemProps> = ({ icon, label, url }) => (
    <MenuLink to={url} className={styles.menu_item} activeClassName={styles.active}>
        <li className={styles.navItem}>
            <FontAwesomeIcon icon={icon} size={'1x'} />
            <p>{label}</p>
        </li>
    </MenuLink>
)

export const MobileNavigation: FC = () => {
    const [isMenuOpen, setMenuOpen] = useState(false)

    return (
        <div className={styles.mobileNavigation}>
            <div className={styles.fullscreenMenu} style={{ display: isMenuOpen ? 'flex' : 'none' }}>
                <FullLogo color={Colors.white} />
                <Menu fullscreen />
                <button onClick={() => setMenuOpen(false)}>
                    <FontAwesomeIcon icon={faTimes} size={'1x'} />
                    Close
                </button>
            </div>
            <ol>
                <NavItem icon={faHome} label="Accueil" url={Routes.home} />
                <NavItem icon={faUserFriends} label="Services" url={Routes.services} />
                <NavItem icon={faEnvelope} label="Contact" url={Routes.contact} />
                <li onClick={() => setMenuOpen(true)}>
                    <FontAwesomeIcon icon={faBars} size={'1x'} />
                    <p>Menu</p>
                </li>
            </ol>
        </div>
    )
}
