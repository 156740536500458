import React, { FC } from 'react'
import { menu, MenuItemCollection } from '../../menu'
import * as styles from './menu.module.scss'
import { MenuLink } from '../link/menuLink'

interface Props {
    fullscreen?: boolean
}

const generateMenu = (menuItems: MenuItemCollection, fullscreen: boolean) =>
    menuItems.map(({ label, children, page }) => (
        <li key={`menu-item-${label}`}>
            <MenuLink to={page} activeClassName={styles.active}>
                {label}
            </MenuLink>
            {children && <div>{generateMenu(children, fullscreen)}</div>}
        </li>
    ))

export const Menu: FC<Props> = ({ fullscreen = false }) => {
    return (
        <nav role="navigation" className={fullscreen ? styles.fullscreenNavigation : ''}>
            <ol className={styles.menu}>{generateMenu(menu, fullscreen)}</ol>
        </nav>
    )
}
