import React from 'react'
import * as styles from './footer.module.scss'
import Button from '../button/button'
import { ContactInformation, useContactInformation } from '../../hooks/useContactInformation'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import majistiLogo from '../../images/logo-majisti.gif'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { Colors } from '../../colors'
import { NameOnlyLogo } from '../lightaus-logo/lightaus-logo'
import { menu } from '../../menu'

export interface Props {
    siteTitle: string
    contactInformation: ContactInformation
}

export const FooterPure: React.FC<Props> = ({ siteTitle, contactInformation }) => {
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.col}>
                    <div className={styles.logo}>
                        <NameOnlyLogo color={Colors.lightBlue} />
                    </div>
                </div>
                <div className={styles.col}>
                    <h2>Plan du site</h2>
                    <ul>
                        {menu.map(({ page, label }) => {
                            return (
                                <li key={`site-map-${label}`}>
                                    <Link to={page}>{label}</Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className={styles.col}>
                    <h2>Nous joindre</h2>
                    <ul>
                        <li>
                            <a href={`tel:${contactInformation.phoneNumberUri}`}>{contactInformation.phoneNumber}</a>
                        </li>
                        <li>
                            <a href={`mailto:${contactInformation.contactEmail}`}>{contactInformation.contactEmail}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className={styles.container}>
                <div className={styles.copy}>
                    &copy; {new Date().getFullYear()} {siteTitle} <br />
                    <span>- </span>Site web cr&eacute;&eacute; par{' '}
                    <a href="https://majisti.com" target="_blank">
                        <img src={majistiLogo} alt="" />
                        &nbsp;Majisti&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faExternalLinkAlt} size={'xs'} />
                    </a>
                    <div className={styles.button_wrapper}>
                        <Button
                            text={'Retour'}
                            color={Colors.turquoise}
                            displayOnMobileOnly={true}
                            onClick={() => {
                                scrollTo('header')
                            }}
                        />
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default (siteTitle: { siteTitle: string }) => {
    const contactInformation = useContactInformation()
    return <FooterPure siteTitle={siteTitle.siteTitle} contactInformation={contactInformation} />
}
